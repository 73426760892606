<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        title="电站列表"
        :data="tableData"
        :columns="tableColumns"
        :selectable="true"
        :selectOnIndeterminate="false"
        :rowSelectable="row => Number(row.stationType) === 1"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())"
        @selection-change="selectionChange">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button
              type="plain"
              @click="handleBatchSet('priceTemplate')"
              v-rbac="'operation:price:charge:pricetemplate'">
              价格模板
            </ykc-button>
            <ykc-button
              v-rbac="'operation:price:charge:config'"
              type="plain"
              @click="handleBatchSet('batchSetting')">
              批量设置
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.pageIndex"
          :page-size.sync="pageInfo.pageSize"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
    <ykc-drawer
      :show.sync="showDrawer"
      title="充电价格策略"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button
              style="margin-left: 8px"
              :type="btn.type"
              v-if="btn.enabled()"
              :key="i"
              @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <AddOrEdit
          ref="addOrEdit"
          :currentRow="currentRow"
          :operateType="operateType"
          :selectedRows="selectedRows"></AddOrEdit>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import AddOrEdit from './AddOrEdit.vue';
  import { chargeStrategy, directPage } from '@/service/apis';
  import { code } from '@/utils';

  export default {
    name: 'ChargingPriceStrategyList',
    components: {
      AddOrEdit,
    },
    data() {
      return {
        showDrawer: false,
        operateType: '',
        selectedRows: [],
        currentRow: null,
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.$refs.addOrEdit.submitForm(() => {
                this.showDrawer = false;
                this.searchTableList();
              });
            },
          },
        ],
        tableData: [],
        tableOperateButtons: [
          {
            enabled: () => code('operation:price:charge:edit'),
            render: (h, button, { row }) => {
              // 互联不能编辑
              if (String(row.stationType) === '2') {
                return null;
              }
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => {
                    this.doEdit(row);
                  }}>
                  编辑
                </ykc-button>
              );
            },
          },
          {
            enabled: () => code('operation:price:charge:detail'),
            render: (h, button, { row }) => {
              // 未配置无详情
              if (String(row.status) === '0') {
                return null;
              }
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => {
                    this.doDetail(row);
                  }}>
                  详情
                </ykc-button>
              );
            },
          },
        ],
        tableColumns: [
          { label: '电站编码', prop: 'stationCode', minWidth: '200px' },
          {
            label: '电站名称',
            prop: 'stationName',
            minWidth: '200px',
            // scopedSlots: {
            //   default: ({ row }) => {
            //     return (
            //       <el-badge
            //         value={row.usedType && Number(row.usedType) ? '待生效' : ''}
            //         type="primary">
            //         <el-tooltip effect="dark" content={row.stationName} placement="top-start">
            //           <span>{row.stationName ?? '--'}</span>
            //         </el-tooltip>
            //       </el-badge>
            //     );
            //   },
            // },
          },
          {
            label: '归属商家',
            prop: 'stationOperatorName',
            minWidth: '200px',
            // scopedSlots: {
            //   default: ({ row }) => {
            //     return (
            //       <el-tooltip effect="dark" content={row.stationOperatorName} placement="top-start">
            //         <span>{row.stationOperatorName}</span>
            //       </el-tooltip>
            //     );
            //   },
            // },
          },
          {
            label: '直联互联',
            prop: 'stationType',
            scopedSlots: {
              defaultTitle: () => '',
              default: ({ row: { stationType } }) => {
                return (
                  {
                    1: '直联',
                    2: '互联',
                  }[stationType] || '一'
                );
              },
            },
          },
          {
            label: '状态',
            prop: 'status',
            scopedSlots: {
              defaultTitle: () => '',
              default: ({ row: { status } }) => {
                return (
                  {
                    0: '未配置',
                    1: '已配置',
                  }[status] || '一'
                );
              },
            },
          },
          { label: '修改人', prop: 'modifiedAccount' },
          { label: '修改时间', prop: 'modifiedTime', minWidth: '200px' },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          stationName: '',
          stationOperatorId: '',
          status: '',
          stationType: '',
          priceType: '',
        },
        OperatorListData: [],
      };
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'stationName',
            label: '电站名称',
            placeholder: '请输入电站名称',
          },
          {
            comName: 'YkcDropdown',
            key: 'stationOperatorId',
            label: '归属商家',
            placeholder: '请输入归属商家',
            data: this.OperatorListData,
            remote: true,
          },
          {
            comName: 'YkcDropdown',
            key: 'status',
            label: '状态',
            placeholder: '请选择状态',
            data: [
              {
                id: '',
                name: '全部',
              },
              {
                id: '0',
                name: '未配置',
              },
              {
                id: '1',
                name: '已配置',
              },
            ],
          },
          {
            comName: 'YkcDropdown',
            key: 'stationType',
            label: '直联互联',
            placeholder: '请输入直联互联',
            data: [
              {
                id: '',
                name: '全部',
              },
              {
                id: '1',
                name: '直联',
              },
              {
                id: '2',
                name: '互联',
              },
            ],
          },
          {
            comName: 'YkcDropdown',
            key: 'priceType',
            label: '费率类别',
            placeholder: '请输入费率类别',
            data: [
              {
                id: '0',
                name: '尖峰平谷',
              },
              {
                id: '1',
                name: '48费率',
              },
            ],
          },
        ];
      },
    },
    created() {
      if (this.$route.query.stationName) {
        this.searchParams.stationName = this.$route.query.stationName;
      }

      this.searchTableList();
      this.requestOperatorList();
    },
    methods: {
      requestOperatorList() {
        directPage.userOperatorList({ keyword: '' }).then(res => {
          // eslint-disable-next-line no-unused-vars
          this.OperatorListData = (res || []).map(({ operatorId, name }) => ({
            name,
            id: String(operatorId),
          }));
        });
      },
      doEdit(row) {
        const { stationId, status } = row;
        if (String(status) === '0') {
          this.operateType = 'single';
          this.currentRow = row;
          this.showDrawer = true;
        } else if (String(status) === '1') {
          chargeStrategy.detail({ stationId }).then(data => {
            this.operateType = 'single';
            this.currentRow = {
              ...row,
              ...(data || {}),
            };
            this.showDrawer = true;
          });
        }
      },
      handleBatchSet(key) {
        switch (key) {
          // 批量设置
          case 'batchSetting':
            if (this.selectedRows.length > 0) {
              this.operateType = 'multiple';
              this.showDrawer = true;
            } else {
              this.$message.warning('请选择数据');
            }
            break;
          // 价格模板
          case 'priceTemplate':
            this.$router.push({
              path: '/operationCenter/priceManage/chargingPriceTemplate',
              query: {},
            });
            break;

          default:
            break;
        }
      },
      doDetail({ stationId }) {
        this.$router.push({
          path: '/operationCenter/priceManage/chargingPriceStrategy/details',
          query: {
            stationId,
          },
        });
      },
      searchTableList() {
        const data = {
          ...this.searchParams,
          size: this.pageInfo.pageSize,
          current: this.pageInfo.pageIndex,
        };
        chargeStrategy.queryForPage(data).then(res => {
          this.tableData = res.records;
          this.pageInfo.total = res.total;
          this.pageInfo.pageIndex = res.current;
        });
      },

      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },

      /** 点击查询按钮 */
      handleSearchConfirm() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },
      onAddDrawerClose(done) {
        done();
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            // 点击确认取消保存后，要重置表单
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      /** 获取勾选id */
      selectionChange(selection) {
        this.selectedRows = [...selection];
      },
    },
  };
</script>

<style lang="scss">
  .el-badge__content.is-fixed {
    position: absolute;
    top: 10px;
    right: -8px;
    -webkit-transform: translateY(-50%) translateX(100%);
    transform: translateY(-50%) translateX(100%);
  }
</style>
