<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <ykc-form-item label="电站名称">
      <div v-if="operateType === 'single'">
        {{ currentRow.stationName }}
      </div>
      <el-scrollbar v-else-if="operateType === 'multiple'" :viewStyle="{ maxHeight: '200px' }">
        <div v-for="item in selectedRows" style="margin: 4px 0" :key="item.stationId">
          {{ item.stationName }}
        </div>
      </el-scrollbar>
    </ykc-form-item>
    <ykc-form-item label="配置方式" prop="configType">
      <ykc-radio
        :data="configTypeData"
        v-model="ruleForm.configType"
        @change="configTypeFn"></ykc-radio>
    </ykc-form-item>
    <ykc-form-item v-if="ruleForm.configType === '2'" label="选择模版" prop="templateId">
      <ykc-dropdown
        v-model="ruleForm.templateId"
        placeholder="请选择模版"
        :data="templateData"
        @change="handleStationChange"></ykc-dropdown>
    </ykc-form-item>
    <peaksValleys ref="ykcPrice" :priceData="priceData" />
    <ykc-form-item
      label="生效方式"
      prop="configType"
      bottomTip="温馨提示：立即生效覆盖当前价格，定时生效覆盖定时生效。">
      <ykc-radio :data="EffectiveData" v-model="effectiveType"></ykc-radio>
    </ykc-form-item>
    <ykc-form-item label="生效时间" prop="useTime" v-if="effectiveType === '1'">
      <ykc-date-picker
        type="datetime"
        limitDate="limitBeforeTime"
        format="yyyy-MM-dd HH:mm:ss"
        v-model="ruleForm.useTime"></ykc-date-picker>
    </ykc-form-item>
  </ykc-form>
</template>

<script>
  import { chargeStrategy } from '@/service/apis';

  import peaksValleys from '@/components/dynamic-compose/peaks-valleys.vue';

  export default {
    props: ['currentRow', 'operateType', 'selectedRows'],
    components: { peaksValleys },
    data() {
      return {
        templateData: [],
        configTypeData: [
          {
            id: '0',
            name: '手动配置',
          },
          {
            id: '2',
            name: '引用模版',
          },
        ],
        EffectiveData: [
          {
            id: '0',
            name: '立即生效',
          },
          {
            id: '1',
            name: '定时生效',
          },
        ],
        priceData: { feeList: [], priceList: [] }, // 电价数据
        rules: {
          configType: [
            {
              required: true,
              message: '请选择配置方式',
              trigger: 'blur',
            },
          ],
          priceType: [
            {
              required: true,
              message: '请选择费率类别',
              trigger: 'blur',
            },
          ],
          templateId: [
            {
              required: true,
              message: '请选择模版',
              trigger: 'blur',
            },
          ],
          priceList: [
            {
              required: true,
              type: 'array',
              message: '请添加价格设置',
              trigger: 'blur',
            },
          ],
          useTime: [
            {
              required: true,
              message: '请选择生效时间',
              trigger: 'blur',
            },
          ],
        },
        ruleForm: {
          configType: '0', // 配置方式 【0：手动配置 1：引用其它站点 2：引用价格模板】
          priceType: '0', // 费率类别 0-尖峰平谷、1-48费率
          templateId: '', // 模版Id
          feeList: [],
          priceList: [], // 价格设置
          useTime: '', // 价格生效时间，定时生效时有值
        },
        effectiveType: '0', // 生效方式
      };
    },
    watch: {
      'ruleForm.priceList': {
        deep: true,
        handler() {
          this.$refs.ruleForm && this.$refs.ruleForm.validateField('priceList');
        },
      },
    },
    created() {
      if (this.operateType === 'single') {
        if (this.ruleForm.priceType === '0') {
          // 如果有定时时间则赋值数据
          if (this.currentRow.useTime) {
            this.ruleForm.useTime = this.currentRow.useTime;
          } else {
            this.ruleForm.useTime = '';
          }
          this.priceData = {
            feeList: this.currentRow.feeList,
            priceList: this.currentRow.priceList,
          };
        }
      } else {
        this.resetPriceData();
      }
    },
    methods: {
      /**
       * 配置方式选择
       */
      configTypeFn(val) {
        if (val) {
          switch (val) {
            case '2':
              this.getTemplateData();
              break;

            default:
              break;
          }
        }
      },
      /**
       * 获取充电价格模板数据
       */
      getTemplateData() {
        chargeStrategy.chargePriceTemplateQueryAll({}).then(res => {
          this.templateData = res.map(({ templateId, templateName }) => ({
            id: String(templateId), // 模板id
            name: templateName, // 模板名称
          }));
        });
      },
      /**
       * 选择模版
       */
      handleStationChange(templateId) {
        if (!templateId) {
          this.resetPriceData();
          return;
        }
        chargeStrategy.chargePriceTemplateDetail({ templateId }).then(res => {
          if (res && typeof res === 'object') {
            if (this.ruleForm.priceType === '0') {
              this.priceData = {
                feeList: res.feeList,
                priceList: res.priceList,
              };
            }
          } else {
            this.resetPriceData();
          }
        });
      },

      /**
       * 重置尖峰平谷数据
       */
      resetPriceData() {
        this.priceData = {
          feeList: [],
          priceList: [],
        };
      },
      /**
       * 提交
       */
      submitForm(callback) {
        console.log('this.ruleForm+++', this.ruleForm);
        if (this.ruleForm.priceType === '0') {
          this.PeaksValleysFun(callback);
        }
      },
      /**
       * 尖峰平谷提交
       */
      PeaksValleysFun(callback) {
        console.log('this.$refs.ykcPrice.saveVerifyOMP()+++', this.$refs.ykcPrice.saveVerifyOMP());
        const promisify = item =>
          new Promise(resolve => {
            item.validate(valid => {
              resolve(valid);
            });
          });
        Promise.all([promisify(this.$refs.ruleForm), this.$refs.ykcPrice.saveVerifyOMP()]).then(
          res => {
            if (res.every(valid => !!valid)) {
              if (this.operateType === 'single') {
                const peaksData = this.$refs.ykcPrice.saveVerifyOMP();
                const parameter = {
                  periodType: '1', // 时段类型 固定传1
                  stationId: this.currentRow.stationId,
                  ...this.ruleForm,
                  ...peaksData,
                };
                console.log('parameter+++', parameter);
                chargeStrategy.edit(parameter).then(response => {
                  console.log('chargeStrategy single edit', response);
                  callback();
                });
              } else if (this.operateType === 'multiple') {
                const peaksData = this.$refs.ykcPrice.saveVerifyOMP();
                const parameter = {
                  periodType: '1', // 时段类型 固定传1
                  stationIds: this.selectedRows.map(({ stationId }) => stationId),
                  ...this.ruleForm,
                  ...peaksData,
                };
                chargeStrategy.batchEdit(parameter).then(response => {
                  console.log('chargeStrategy multiple edit', response);
                  callback();
                });
              }
            }
          }
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  ._line {
    display: flex;
    & > * {
      margin-right: 5px;
    }
  }
</style>
